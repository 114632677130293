import Block from "@/components/wrappers/block"
import styles from "./button.module.css"
import Button from "@/common/button/button"
import { trackProductClick } from "@/lib/helpers/tagManager"
import classNames from "classnames/bind"

export default function CoreButton({ block, tracking, settings = {} }) {
  if (!block.attrs.url) {
    return null
  }

  const cx = classNames.bind(styles)
  const dataProps = {}
  const blockClasses = {
    button: true,
    "has-width": block.attrs.width,
    "width-25": block.attrs.width === 25,
    "width-50": block.attrs.width === 50,
    "width-75": block.attrs.width === 75,
    "width-100": block.attrs.width === 100,
    "multiple-wrap": settings?.layout?.flexWrap === "wrap" && settings.multiple,
    "toggle-sso-modal": block?.attrs?.ssoToggle
  }

  if (block?.attrs?.ssoToggle) {
    dataProps["data-service"] = block?.attrs?.ssoToggle
  }

  return (
    <Block
      block={block}
      noStyling={true}
      className={cx(blockClasses)}
      dataProps={dataProps}>
      <Button
        tagName="a"
        ariaLabel={block.text}
        theme={block.attrs.theme}
        href={block.attrs.url}
        target={block.attrs.target}
        onClick={() => trackProductClick(tracking, "gift")}>
        {block.text}
      </Button>
    </Block>
  )
}
